<template>
    <div style="position:relative;"  class="crane-and-rigging">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Efficient Project Management Software for Excavation and Earthmoving Contractor<span style="color:#00c8d8;"></span></h1>
                <p><b>Enhance Visibility and Coordination on Excavation and Earthmoving Jobsite</b></p>
                <div>
                    <p>Record Time is flexible and adaptable business management software that includes robust time tracking capabilities and can be efficiently customized and applied to various industries, including excavation and earthmoving. Excavation and earthmoving contractors have found ways to make the most of its features to manage their business operations. With Record TIME, contractors can enhance their operational efficiency, improve project coordination, and gain better insights into their business performance.
                        <br><br> Besides, digital docketing, pre-start checklists, plant management, and <a href="https://recordtime.com.au/field-staff-real-time-tracking">field staff real time tracking</a> are some of the features of Record TIME that contractors are finding useful for their excavation and earthmoving business.
                </p>
                </div>
                <br><br>
                <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/blog/best-construction-scheduling-software')" class="mr-7 btn-1" elevation="0">
                    Read More
                </v-btn>
                <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                        Get A Demo Now
                    </v-btn><br/><br/>
            </div>
            <div  class="right-banner" style="text-align: right;">
                <img :src="`${publicPath}assets/whoweserve/earthworks-and-excavation.webp`" alt="all-in-one software for earthworks and excavation contractors"  style="max-width: 90%;">
            </div>
        <div style="clear: both;"></div>
        <br><br>
        <br><br>
        <h2>Digital Docketing and Real-time Tracking: A Game-Changer Contractor Software For Earthmoving and Excavation</h2>
        <p>Say goodbye to traditional paper-based methods that slow you down. Instead, Record TIME empowers you to manage your projects efficiently from start to finish. From tracking equipment utilisation to monitoring project progress, every aspect of your operation is just a click away. Stay informed and in control with real-time project tracking. Whether you're in the office or on the field, Record TIME keeps you connected to your projects.
        <br><br>What's more, with a centralised database, real-time location tracking, and job scheduling features, our software provides complete visibility into your owned and rented equipment. In addition, different projects, clients, and business departments can quickly share timesheets, health and safety forms, and equipment locations in real time.
        </p>
        </div>

        <v-container>
            <div class="gray-container">  
                     <div class="left-blog1">
                         <br>
                         <h3>Simplify Pre Start Checklist, Equipment Scheduling, and Remote Approval of Documents</h3>
                         <p style="text-align:left;">Users can easily create comprehensive pre start checklists for all equipment (<a href="https://recordtime.com.au/blog/vehicle-safety-checks-and-list-of-pre-start-checklist" target="_blank">excavator</a>, vehicle, forklift, truck, ewp) in their fleet. These checklists help the team to conduct thorough inspections, identify potential issues, and address maintenance needs proactively. As a result, by keeping your equipment in peak working condition, you can minimise downtime and maximise productivity on the job site.
                            <br><br> Additionally, Record TIME empowers you to obtain remote approvals for your job site work, no matter where you are. Whether it's seeking approval for project milestones, change orders, or materials requisitions, our platform enables quick and secure communication between your team in the field and decision-makers in the office. With this seamless connectivity, you can maintain a well-coordinated and agile operation, leading to increased project success and client satisfaction.
                    </p>
     
                     </div>
                     <div class="right-blog1">
                        <br><br>
                         <img :src="`${publicPath}assets/whoweserve/excavation-and-earthmoving.webp`" alt="listing, scheduling, pre start checklist for excavation and earthmoving equipment">
                     </div>
                     <div style="clear:both;"></div>
            </div>
                 </v-container>
     
        <v-container>
                <div class="gray-container">
                <div style="display: inline-table;margin-top:10px" class="list">
                    
                    <h2 style="font-size:42px; text-align:center;">Empowering Excavation and Earthmoving Team's Communication</h2>
                    <br>  <br>
                  <center>  <img :src="`${publicPath}assets/whoweserve/why-record-time-for-excavation-and-earthmoving.webp`" alt="Excavation and Earthmoving" style="width: 100%;"></center>
                </div>
                </div>

                <br>
                         <h2>If you're a contractor and looking for affordable and user-friendly business management software for excavation and earthmoving projects, look no further than Record TIME.</h2>
            
                  
                        <br>
                        <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                    @click="redirectToLink('https://recordtime.com.au/contact')" class="mr-7 btn-1" elevation="0">
                    Contact Us
                </v-btn>
                
        </v-container>


       
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>

</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 45%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 55%; 
        float: right;
        padding: 0px 20px 20px;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
            h1{ font-size: 40px !important;}
        }
    }
    .heavy-civil{
        .banner-img{
            @media only screen and (max-width: 600px) {
            position: relative !important;
            }

            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .banner-img{
        @media only screen and (max-width: 600px) {
            position: relative !important;
        }

        @media only screen and (max-width: 1280px) {
            position: relative !important;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: left;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 600px);
      padding-right: 15px;
      @media only screen and (max-width: 1280px) {
        max-width: 100%;
      }
    }
    .right-blog1{
      width: 600px;
      float:right;
      @media only screen and (max-width: 1280px) {
        width: 100%;
      }
      img{
        max-width: 600px;
        width: 100%;
        @media only screen and (max-width: 1280px) {
            max-width: 100%;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>

import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
    title: 'Excavation and Earthmoving Contractor Software | Record TIME',
    // titleTemplate: 'Record TIME → %s',
    meta: [
      {name: 'name', content: ''},
      {name: 'description', content: 'Transform Your Business with Record TIME Excavation and Earthmoving Contractor Software. Digital Docketing and Real-time Tracking solution'},
      {name: 'keyword', content: 'excavation and earthmoving contractor software'}
      ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/excavation-and-earthmoving-contractor-software' }
    ]
  },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },

        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
